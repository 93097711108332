<template>
  <div>
    <!-- 还需要一个系统配置的功能 配置全局的 logo 名称  图片-->
    <!--  系统集合配置： 默认首页 页面标题 标题点击跳转 页面logo 页面logo跳转 隐藏菜单 -->
    <el-drawer
      title="详情"
      :visible.sync="drawer"
      :before-close="handleClose"
      :max-height="maxHeight"
      size="50%"
    >
      <!-- 列表区域 -->
      <el-button @click="addButton()">新增</el-button>
      <el-table
        :data="dataList"
        border
        row-key="code"
        stripe
        :tree-props="{
          children: 'routeMenInterfaceListVOList',
          hasChildren: 'hasRouteMenInterfaceListVOList',
        }"
      >
        <el-table-column
          label="选择"
          width="70"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <el-radio
              class="radio"
              v-model="questionnaireId"
              :label="scope.row.id"
              @click.native.stop.prevent="getCurrentRow(scope.row)"
              >&nbsp;</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column label="归属系统" prop="productSourceName" />
        <el-table-column label="菜单名称" prop="name" />
        <el-table-column
          label="菜单类型"
          prop="type"
          :formatter="mensTypeMenus"
        >
          <!-- <template slot-scope="scope">
            <el-tag :type="tableRowClassName(scope.row.type)">{{
              scope.row.type
            }}</el-tag>
          </template> -->
        </el-table-column>

        <el-table-column label="菜单编码" prop="code" />
        <el-table-column label="数据标识" prop="dataSign" />
        <el-table-column label="二级类型" prop="twoType" />
        <el-table-column label="api全路径" prop="apiAllPath" />
        <el-table-column label="api方式" prop="apiMethod" />
        <el-table-column label="状态" prop="status" />
        <el-table-column label="权限标识" prop="perms" />
        <el-table-column label="权限策略" prop="permsTactics" />
        <el-table-column label="排序" prop="sort" />
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="editMenu(scope.$index, scope.row)"
              disabled
              >删除</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="editMenu(scope.$index, scope.row)"
              disabled
              >添加下级</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="editMenu(scope.$index, scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
    <addMenus ref="addMenuModules" />
  </div>
</template>
  <script>
import { menuV1ListApi } from "@/api/system/systemCommon/menu.js";
import addMenus from "./addMenus.vue";
export default {
  components: {
    addMenus,
  },
  data() {
    return {
      drawer: false,
      dataList: [],
      maxHeight: 400, // 设置max-height为窗口高度的一部分
      questionnaireId: "",
      templateSelection: {},
      radio: null,
      menusTypeList: [],
      systemList: [],
      queryMenuFrom: {
        type: null,
      },
      parentsCode: null,
      apiMethodList: [],
      apiTypeList: [],
      twoTypeList: [],
      commonBnCssTypeList: [],
      productSources:'',
    };
  },

  methods: {
    show(row, type) {
      this.dataList = row.bindButtonList;
      this.drawer = true;
      this.initData();
      this.parentsCode = row.code;
      this.productSource=row.productSource
      this.menuV1ListButton(this.parentsCode, 1);
      this.getEnumButtonList();
    },
    getEnumButtonList() {
      this.twoTypeList = this.$parent.twoTypeList;
      this.commonBnCssTypeList = this.$parent.commonBnCssTypeList;
      this.apiMethodList = this.$parent.apiMethodList;
      this.apiTypeList = this.$parent.apiTypeList;
    },
    menuV1ListButton(code, type) {
      this.queryMenuFrom = this.$parent.queryMenuFrom;
      this.queryMenuFrom.code = code;
      this.queryMenuFrom.productSource=this.productSource
      menuV1ListApi(this.queryMenuFrom).then((res) => {
        this.dataList = res.result[0].bindButtonList;
      });
    },
    initData() {
      this.systemList = this.$parent.systemList;
    },
    editMenu(index, row) {
      row.parentsCode = this.parentsCode;
      this.$refs.addMenuModules.show(row, 2, 2);
    },
    tableRowClassName(row) {
      if (row === 2) {
        return "success";
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.cancelForm();
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.drawer = false;
      this.$parent.questionnaireId = null;
      this.$parent.queryMenuFrom.code = null;
      this.$parent.menuListButton();
    },
    getCurrentRow(row) {
      if (this.questionnaireId == row.id) {
        this.questionnaireId = null;
        this.id = null;
        this.templateSelection = {};
      } else {
        this.questionnaireId = row.id;
        this.id = row.id;
        this.templateSelection = row;
      }
    },
    mensTypeMenus(row, column) {
      this.menusTypeList=this.$parent.menusTypeList
      return this.selectDictLabel(this.menusTypeList, row.type);
    },
    addButton(){
      var row={
        parentsCode:this.parentsCode
      }
      this.$refs.addMenuModules.show(row, 1, 2);
    }
  },
};
</script>
  
  <style  lang="less" scoped>
</style>